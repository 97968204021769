import { useCallback, useState } from 'react';

import { useContext } from 'shared/common/packages/Filters/shared/utils/useContext';

import { getPromoSearchTypesOnboardingCookie, setPromoSearchTypesOnboardingCookie } from '../../../utils';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

export const usePromoSearchTypesOnboarding = (): IOnboardingTooltipResult => {
  const { features } = useContext();

  const isOnboardingEnabled = features.newbuildingPromoSearchTypesOnboardingEnabled;
  const isOnboardingShown = getPromoSearchTypesOnboardingCookie();

  const [open, setOpen] = useState(isOnboardingEnabled && !isOnboardingShown);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setPromoSearchTypesOnboardingCookie();
  }, []);

  return {
    open,
    closeHandler,
  };
};
